



















// Core
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Store
import { userNamespace} from '@store/user'
import { documentsNamespace } from '@store/documents'
import { commonNamespace } from '@store/common'

// Types
import { DocumentsActionTypes } from '@store/documents/Types'
import { CommonActionTypes } from '@store/common/Types'
import { UserActionTypes } from '@store/user/Types'
import { TeamActions } from '@store/team/types'

// Interfaces
import { IUser, IProject } from '@store/user/Interface'
import { Paginate, RequestParams } from '@store/interfaces'
import { Team } from '@store/team/interfaces'
import { Role } from '@store/common/Interface'

// Modules
const NSTeam = namespace('teamModule')

// Logs
import http, {addResponseHandler} from "@/http";

@Component({
  components: {
    'v-header': () => import('./components/Header.vue'),
    'v-footer': () => import('./components/Footer.vue'),
    'v-nav': () => import('./components/Nav.vue'),
    'v-nav-admin': () => import('./components/NavAdmin.vue'),
    'v-preloader': () => import('./components/Preloader.vue'),
  },
})
export default class App extends Vue {
  @NSTeam.Action(TeamActions.A_FETCH_TEAM)
  private fetchTeam!: (params: RequestParams) => Promise<Paginate<Team>>

  @userNamespace.State('selectedProject')
  private selectedProject!: number

  @commonNamespace.State('isAuthUser')
  private isAuthUser!: boolean

  @commonNamespace.State('userToken')
  private token!: string

  @userNamespace.State('user')
  private user!: IUser

  @userNamespace.Action(UserActionTypes.A_FETCH_USER)
  private fetchUser!: () => Promise<any>

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_TYPES)
  private fetchDocumentTypes!: () => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_MARKS)
  private fetchMarks!: () => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_AUTH_USER)
  private userAuth!: (isAuth) => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_USER_ROLE_NATURAL)
  private fetchRolesNatural!: () => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_USER_ROLE_LEGAL)
  private fetchRolesLegal!: (params: RequestParams) => Promise<Role[]>

  @commonNamespace.Action(CommonActionTypes.A_FETCH_TASK_TYPE)
  private fetchTaskType!: () => Promise<any>

  @commonNamespace.Action(CommonActionTypes.A_USER_TOKEN)
  private fetchToken!: (token) => Promise<any>

  @userNamespace.Getter('project')
  private project!: IProject

  created() {
    this.fetchDocumentTypes()
    this.fetchRolesNatural()

    this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))

    if (this.token !== '') {
      this.fetchUser()
        .then(() => {
          this.fetchCommon()
          this.fetchTeam({
            projectId: this.selectedProject,
          })

          if (this.user.role === 'admin') {
            this.userAuth(true)
            if (!this.$route.path.includes('admin')) {
              this.$router.push({ name: 'admin' }, () => {
                this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))
              })
            } else {
              this.userAuth(true)
              this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))
            }
          } else {
            if (this.$route.name === 'admin') {
              this.$router.push({ name: 'home' }, () => {
                this.userAuth(true)
                this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))
              })
            } else {
              this.userAuth(true)
              this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))
              this.fetchRolesLegal({ projectId: this.selectedProject })
            }
          }
        })
        .catch(error => {
          this.$router.push({ name: 'auth' })
          console.error(error)
          if (error.request.status === 401) {
            localStorage.removeItem('token')
            this.userAuth(false)
            this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))
          }
        })
    }

    addResponseHandler(
      success =>{
        if(success.config.method !== 'get' && !/logs/.test(success.config.url) && this.user.name){
          http.post('/logs', {user_id:  this.user.id, action: success.config.method, path: success.config.url, status:true})
        }

        return success
      }, reason => {
        if(reason.response && reason.response.config.method !== 'get' && !/logs/.test(reason.response.config.url)  && this.user.name){
          http.post('/logs', {user_id:  this.user.id, action: reason.response.config.method, path: reason.response.config.url, status:false})
        }

        return Promise.reject(reason);
      });
  }

  private fetchCommon() {
    this.fetchMarks()
    this.fetchTaskType()
  }
}
