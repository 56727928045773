import {ActionTree} from 'vuex'
import {IRootState} from '@/store'
import http from '@/http'
import {IDocument, IDocumentsState, IStatusDocument} from './Interface'
import {DocumentsActionTypes, DocumentsMutationTypes} from './Types'
import {AxiosResponse} from 'axios'
import {Paginate, RequestParams} from '../interfaces'
import {ElUploadInternalFileDetail} from 'element-ui/types/upload'

export const actions: ActionTree<IDocumentsState, IRootState> = {
  async [DocumentsActionTypes.A_DOCUMENTS_INCOMING](
    {commit, state},
    params: RequestParams,
  ): Promise<Paginate<IDocument>> {
    try {
      const types = [...state.documentTypes]
      types.splice(
        state.documentTypes.findIndex(value => Number(value.id) === 7),
        1,
      )
      const incomingId = types.map(item => {
        return item.id
      })
      const {data}: AxiosResponse<Paginate<IDocument>> = await http.get('documents', {
        params: {
          ...params,
          type_ids: incomingId,
        },
      })

      commit(DocumentsMutationTypes.M_DOCUMENTS_INCOMING, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENTS_BY_TYPES]({commit}, params): Promise<void> {
    const response = await http.get('documents', {
      params: {
        project_id: params.projectId,
        type_ids: [params.category.id],
        count: 12,
        page: params.currentPage,
      },
    })

    commit(DocumentsMutationTypes.M_DOCUMENTS_BY_TYPES, {
      category: params.category.bpm_entity_name,
      data: response.data,
    })
    return response.data
  },

  async [DocumentsActionTypes.A_DOCUMENTS_ALL]({commit, state}, project): Promise<any> {
    try {
      const types = state.documentTypes

      let typeIds: number[] = []
      if (project.filter.category === null || project.filter.category === '') {
        typeIds = types.map(item => {
          return item.id || 0
        })
      } else {
        typeIds = [project.filter.category]
      }

      const response = await http.get('documents', {
        params: {
          project_id: project.projectId,
          type_ids: typeIds,
          count: 8,
          page: project.filter.insidePage,
          mark_id: project.filter.markId,
          search: project.filter.search,
        },
      })

      commit(DocumentsMutationTypes.M_DOCUMENTS_ALL, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENTS_EXECUTIVE]({commit}, params): Promise<any> {
    try {
      const response = await http.get('documents', {
        params: {
          project_id: params.projectId,
          type_ids: [7],
          page: params.currentPage,
          status: params.statusId,
        },
      })

      commit(DocumentsMutationTypes.M_DOCUMENTS_EXECUTIVE, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENTS_TYPES]({commit}): Promise<any> {
    try {
      const response = await http.get('document-types')

      commit(DocumentsMutationTypes.M_DOCUMENTS_TYPES, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_FETCH_DOCUMENTS_STATUS]({commit, rootState}): Promise<IStatusDocument[]> {
    try {
      const {data} = await http.get('document-status', {
        params: {
          project_id: rootState.storeUser.selectedProject,
        },
      })

      commit(DocumentsMutationTypes.M_FETCH_DOCUMENTS_STATUS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_GET_DOCUMENTS_STATUS_FOR_PROJECT]({commit, rootState}, projectId: number): Promise<IStatusDocument[]> {
    try {
      const {data} = await http.get('document-status', { params: { projectId }, })
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_FETCH_DOCUMENTS_BY_VOLUME]({commit}, params): Promise<any> {
    try {
      const {data} = await http.get('documents', {
        params: {
          project_id: params.projectId,
          type_ids: [7],
          status_id: params.statusId,
          ierarchi: 1,
        },
      })

      commit(DocumentsMutationTypes.M_FETCH_DOCUMENTS_BY_VOLUME, data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_CHANGE_STATUS]({commit}, params): Promise<any> {
    try {
      const {data} = await http.post('document-status', params)

      commit(DocumentsMutationTypes.M_CHANGE_STATUS, data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENTS_UPLOAD]({commit}, payload): Promise<IDocument> {
    try {
      const formData = new FormData()

      formData.set('project_id', payload.projectId)
      formData.set('code', payload.code)
      formData.set('mark_id', payload.markId)
      formData.set('type_id', payload.typeId)

      payload.files.forEach((file: ElUploadInternalFileDetail) => {
        formData.append('document[]', file.raw)
      })

      const {data}: AxiosResponse<IDocument> = await http.post('documents', formData)

      commit(DocumentsMutationTypes.M_DOCUMENTS_UPLOAD, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.CLEAR_DOCUMENTS_BY_TYPES]({commit}, type): Promise<any> {
    commit(DocumentsMutationTypes.M_CLEAR_DOCUMENTS_BY_TYPES, type)
  },

  async [DocumentsActionTypes.A_CLEAR_DOCUMENTS_EXECUTIVE]({commit}): Promise<any> {
    commit(DocumentsMutationTypes.M_CLEAR_DOCUMENTS_EXECUTIVE)
  },

  async [DocumentsActionTypes.A_MAKE_LIST_WORKS]({commit}, projectId): Promise<any> {
    try {
      const response = http.put(`projects/${projectId}/make-work-list`)

      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_FETCH_DOCUMENTS_COUNT_NEW]({commit, rootState}): Promise<number> {
    try {
      const {data} = await http.get('documents/count-new', {
        params: {
          project_id: rootState.storeUser.selectedProject,
          type_ids: [1, 2, 3, 4, 5, 6],
        },
      })

      commit(DocumentsMutationTypes.M_SET_DOCUMENTS_COUNT_NEW, data.count)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_FETCH_DOCUMENTS_EXEC_COUNT_NEW]({commit, rootState}): Promise<number> {
    try {
      const {data} = await http.get('documents/count-new', {
        params: {
          project_id: rootState.storeUser.selectedProject,
          type_ids: [7],
        },
      })

      commit(DocumentsMutationTypes.M_SET_DOCUMENTS_EXEC_COUNT_NEW, data.count)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENT_READ]({commit}, {
    documentId = -2,
    documentIds = []
  }: { documentId: number, documentIds: number[] }): Promise<void> {
    try {
      if (documentId > -1) {
        await http.post(`documents/${documentId}/read`)

        commit(DocumentsMutationTypes.M_DOCUMENT_READ, {documentId})
      } else if (documentIds.length > 0) {
        await http.post(`documents/${documentIds}/read`)

        commit(DocumentsMutationTypes.M_DOCUMENT_READ, {documentId: -2, documentIds})
      } else {
        console.log(documentIds)
        console.log(documentId)
      }

    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DELETE_DOCUMENT]({commit}, documentId: number): Promise<void> {
    try {
      await http.delete(`documents/${documentId}`)

      // commit(DocumentsMutationTypes.M_DOCUMENT_READ, documentId)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENT_HISTORY]({commit}, documentId: number): Promise<void> {
    try {
      const {data} = await http.get(`documents/${documentId}/history`)
      commit(DocumentsMutationTypes.M_DOCUMENT_HISTORY, data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_DOCUMENT_KITS_ALL]({commit}, {project_id}): Promise<void> {
    try {
      const {data} = await http.get(`document_kits`, {params: {project_id}})

      commit(DocumentsMutationTypes.M_DOCUMENT_KITS_ALL, data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [DocumentsActionTypes.A_SIGN_UPLOAD]({commit}, {sign, user_id, document_id}): Promise<void> {
    try {
      await http.post('/document-sign', {sign, user_id, document_id})
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
