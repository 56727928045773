import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

const client = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://smart-engineers-back.loc/api/' : '/api/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

client.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  reason => Promise.reject(reason),
)

export function addResponseHandler(success, error){
  client.interceptors.response.use(success, error);
}

export function getBaseUrl(): string{
  if(client.defaults.baseURL) {
    return client.defaults.baseURL.toString();
  } else {
    return '/api/'
  }
}



export default applyCaseMiddleware(client)
