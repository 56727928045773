import { ICode, ICommonState } from './Interface'
import { MutationTree } from 'vuex'
import { CommonMutationTypes } from './Types'

export const mutations: MutationTree<ICommonState> = {
  [CommonMutationTypes.M_MARKS](state, payload: any) {
    state.marks = payload.data
  },

  [CommonMutationTypes.M_CODES](state, payload: ICode[]) {
    state.codes = payload
  },

  [CommonMutationTypes.M_AUTH_USER](state, payload: any) {
    state.isAuthUser = payload
  },

  [CommonMutationTypes.M_USER_TOKEN](state, payload: any) {
    state.userToken = payload
  },

  [CommonMutationTypes.M_FETCH_TASK_TYPE](state, payload: any) {
    state.typeTask = payload
  },

  [CommonMutationTypes.M_USER_ROLE_NATURAL](state, payload: any) {
    state.rolesNatural = payload
  },

  [CommonMutationTypes.M_USER_ROLE_LEGAL](state, payload: any) {
    state.rolesLegal = payload
  },

  [CommonMutationTypes.M_CHANGE_USER_ROLE_LEGAL](state, payload: number) {
    const roles = state.rolesLegal.map(role => {
      if (role.id === payload && role.showForm === true) {
        role.isBusy = true
      }

      return role
    })

    state.rolesLegal = roles
  }
}
