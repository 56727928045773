import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const title = 'Dacon.pro'

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/home/Home.vue'),
      meta: {title: 'Главная'}
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('./views/auth/Auth.vue'),
    },
    {
      path: '/admin',
      name: 'admin',
      redirect: '/admin/projects',
      component: () => import('./views/admin/Admin.vue'),
      children: [
        {
          path: 'projects',
          component: () => import('./views/admin/views/projects/ProjectsContainer.vue'),
          meta: {title:'Проекты'},
          children: [
            {
              path: '',
              name: 'admin-projects',
              component: () => import('./views/admin/views/projects/Projects.vue'),
            },
            {
              path: ':projectId',
              name: 'admin-project',
              component: () => import('./views/admin/views/projects/Project.vue'),
            },
          ],
        },
        {
          path: 'users',
          component: () => import('./views/admin/views/users/UsersContainer.vue'),
          meta: {title:'Пользователи'},
          children: [
            {
              path: '',
              name: 'admin-users',
              component: () => import('./views/admin/views/users/Users.vue'),
            },
            {
              path: ':userId',
              name: 'admin-user',
              component: () => import('./views/admin/views/users/User.vue'),
            },
            {
              path: ':userId/logs',
              name: 'admin-user-logs',
              component: () => import('./views/admin/views/users/UserLogs.vue'),
              meta: {title:'Логи пользователя'},
            },
          ],
        },
      ],
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('./views/projects/Projects.vue'),
    },
    {
      path: '/upload',
      name: 'upload',
      component: () => import('./views/upload/Upload.vue'),
    },
    {
      path: '/library',
      name: 'library',
      component: () => import('./views/library/Library.vue'),
      meta: {title:'Документы проекта'},
    },
    {
      path: '/work-list',
      name: 'workList',
      component: () => import('./views/workList/WorkList.vue'),
      redirect:{name:'workListStruct'},
      meta: {title:'Список работ'},
      children:[
        {
          path:'struct',
          name: 'workListStruct',
          component: () => import('@views/workList/WorkListStruct.vue'),
        },
        {
          path:'complect',
          name: 'workListComplect',
          component: () => import('@views/workList/WorkListComplect.vue'),
        }
      ]
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/about/About.vue'),
      meta: {title:'Проект'},
    },
    {
      path: '/executive-documents',
      name: 'executiveDocuments',
      component: () => import('./views/executiveDocuments/ExecutiveDocuments.vue'),
      meta: {title:'Исполнительная документация'},
    },
  ],
})

router.beforeEach((to, from, next) => {
  const route = [...to.matched].reverse().find(el => el.meta.title)

  if(route){
    document.title = `${title} - ${route.meta.title}`
  } else {
    document.title = title
  }

  next()
})

export default router
