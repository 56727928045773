import { ActionTree, ActionContext } from 'vuex'
import { IRootState } from '../../store'
import { ICode, ICommonState, Role } from './Interface'
import { CommonActionTypes, CommonMutationTypes } from './Types'
import http from '@/http'
import { IUserState } from '@store/user/Interface'
import { RequestParams } from '../interfaces'

export const actions: ActionTree<ICommonState, IRootState> = {
  async [CommonActionTypes.A_MARKS]({ commit, rootState }, params): Promise<any> {
    try {
      const response = await http.get('/work-marks')

      commit(CommonMutationTypes.M_MARKS, response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CommonActionTypes.A_CODES]({ commit }, params: RequestParams): Promise<ICode[]> {
    try {
      const { data } = await http.get('/work-codes', { params })

      commit(CommonMutationTypes.M_CODES, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CommonActionTypes.A_AUTH_USER]({ commit, rootState }, isAuthUser): Promise<any> {
    try {
      commit(CommonMutationTypes.M_AUTH_USER, isAuthUser)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CommonActionTypes.A_USER_TOKEN]({ commit, rootState }, token): Promise<any> {
    try {
      commit(CommonMutationTypes.M_USER_TOKEN, token)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CommonActionTypes.A_USER_ROLE_NATURAL]({ commit, rootState }, token): Promise<any> {
    try {
      const response = await http.get('natural-person-roles')

      commit(CommonMutationTypes.M_USER_ROLE_NATURAL, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CommonActionTypes.A_USER_ROLE_LEGAL]({ commit }, params: RequestParams): Promise<Role[]> {
    try {
      const { data } = await http.get('legal-persons-roles', { params })

      commit(CommonMutationTypes.M_USER_ROLE_LEGAL, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [CommonActionTypes.A_FETCH_TASK_TYPE]({ commit, rootState }, token): Promise<any> {
    try {
      const response = await http.get('/task-types')

      commit(CommonMutationTypes.M_FETCH_TASK_TYPE, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
