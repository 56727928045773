import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { actions } from './Action';
import { mutations } from './Mutation';
import { IDocumentsState } from './Interface';
import { IRootState } from '@/store';

export const documentsNamespace = namespace('storeDocuments');

Vue.use(Vuex);

class Documents implements IDocumentsState {
  public documentsIncoming = {
    documentList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
  };
  public documentStatus = [];
  public documentsByVolume = {};
  public documentsExecutive = {
    documentList: [],
    currentPage: 0,
    lastPage: 0,
    total: 0,
  };
  public documentsAll = {
    documentList: [],
    lastPage: 0,
    currentPage: 0,
    perPage: 0,
    total: 0,
  };
  public documentTypes = [];
  public documentByTypes = {};
  public documentCountNew = 0;
  public documentExecCountNew = 0;

  public documentHistory = []

  public documentKits = [];
}

export const storeDocuments: any = {
  namespaced: true,
  state: () => new Documents(),
  actions,
  mutations,
} as Module<IDocumentsState, IRootState>;
