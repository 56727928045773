export const enum UserActionTypes {
  A_LOGIN = 'A_LOGIN',
  A_LOGOUT = 'A_LOGOUT',
  A_FETCH_USER = 'A_FETCH_USER',
  A_FETCH_TASKS = 'A_FETCH_TASKS',
  A_FETCH_COMPLETE = 'A_FETCH_COMPLETE',
  A_ADD_TASK = 'A_ADD_TASK',
  A_FINISH_TASK_OUT = 'A_FINISH_TASK_OUT',
  A_FINISH_TASK_IN = 'A_FINISH_TASK_IN',
  A_CHANGE_PROJECT = 'A_CHANGE_PROJECT',
  A_GET_ACCESS_USER_STATUSES = 'A_CHANGE_ACCESS_USER_STATUSES',
}

export const enum UserMutationTypes {
  M_LOGIN = 'M_LOGIN',
  M_LOGOUT = 'M_LOGOUT',
  M_FETCH_USER = 'M_FETCH_USER',
  M_FETCH_TASKS = 'M_FETCH_TASKS',
  M_SELECT_PROJECT = 'M_SELECT_PROJECT',
  M_UPDATE_PROJECT = 'M_UPDATE_PROJECT',
  M_FETCH_COMPLETE = 'M_FETCH_COMPLETE',
  M_ADD_TASK = 'M_ADD_TASK',
  M_FINISH_TASK = 'M_FINISH_TASK',
  M_CLEAR_TASK = 'M_CLEAR_TASK',
  M_CHANGE_ACCESS_USER_STATUSES = 'M_CHANGE_ACCESS_USER_STATUSES',
}
