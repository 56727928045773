import { Module } from 'vuex'

import { actions } from './actions'
import { mutations } from './mutations'

import { ProjectsState } from './interfaces'
import { IRootState } from '@/store'

export const state = (): ProjectsState => ({
  projects: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },

  projectList: [],

  project: {
    id: 0,
    bpmId: '',
    name: '',
    description: '',
    address: '',
    startDate: '',
    endDate: '',
    newDocuments: 0,
    users: [],
    workListRequested: false,
  },
})

export const ProjectsModule: Module<ProjectsState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
