import {GetterTree} from 'vuex';
import {IUserState} from './Interface';
import {Project} from '@store/projects/interfaces';
import {IRootState} from '@/store';
import {IStatusDocument} from '@store/documents/Interface';

export const getters: GetterTree<IUserState, IRootState> = {
  project(state): Project | null {
    const {projects} = state.user;
    const idSelectedProject = state.selectedProject;

    let selectedProject: Project | null = null;

    if (projects.length) {
      selectedProject = projects.find((project) => project.id === idSelectedProject) || null;
    }

    return selectedProject;
  },
  accessedDocumentStatusesForProject(state) {
    const map:  { [key: number]: boolean } = {0:true}

    state.documentStatusesForProject.forEach((item: IStatusDocument) => {
      map[item.id] = true
    })

    return map;
  }
};
