export const enum CommonActionTypes {
  A_MARKS = 'A_MARKS',
  A_CODES = 'A_CODES',
  A_AUTH_USER = 'A_AUTH_USER',
  A_USER_TOKEN = 'A_USER_TOKEN',
  A_USER_ROLE_NATURAL = 'A_USER_ROLE_NATURAL',
  A_USER_ROLE_LEGAL = 'A_USER_ROLE_LEGAL',
  A_FETCH_TASK_TYPE = 'A_FETCH_TASK_TYPE',
}
export const enum CommonMutationTypes {
  M_MARKS = 'M_MARKS',
  M_CODES = 'M_CODES',
  M_AUTH_USER = 'M_AUTH_USER',
  M_USER_ROLE_NATURAL = 'M_USER_ROLE_NATURAL',
  M_USER_TOKEN = 'M_USER_TOKEN',
  M_USER_ROLE_LEGAL = 'M_USER_ROLE_LEGAL',
  M_CHANGE_USER_ROLE_LEGAL = 'M_CHANGE_USER_ROLE_LEGAL',
  M_FETCH_TASK_TYPE = 'M_FETCH_TASK_TYPE',
}
