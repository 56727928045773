import { Module } from 'vuex';

import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

import { IRootState } from '@/store';
import { UsersState } from './interfaces';

export const state = (): UsersState => ({
  users: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },

  userList: [],

  user: {
    id: 0,
    bpmId: '',
    fullName: '',
    email: '',
    phone: '',
    role: '',
    roleId: 0,
    isAdmin: false,
    isActive: false,
    createdAt: '',
    updatedAt: '',
    projects: [],
  },

  roles: [],

  naturalPersons: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },

  naturalPersonList: [],

  legalPersons: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },

  legals: [],

  legalPersonsList: [],
})

export const UsersModule: Module<UsersState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
