import { ActionTree } from 'vuex'
import http from '@/http'
import { IRootState } from '@/store'
import { Task, TaskState } from './interfaces'
import { TaskActions, TaskMutations } from './types'
import { AxiosResponse } from 'axios'
import { Paginate } from '../interfaces'
import { ElUpload, ElUploadInternalFileDetail } from 'element-ui/types/upload'

interface ElUploadInternalFileDetailWrapper {
  file: ElUploadInternalFileDetail,
}

export const actions: ActionTree<TaskState, IRootState> = {
  async [TaskActions.A_FETCH_TASKS]({ commit }, params): Promise<Paginate<Task>> {
    try {
      const { data }: AxiosResponse<Paginate<Task>> = await http.get('tasks', { params })

      commit(TaskMutations.M_FETCH_TASKS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [TaskActions.A_CREATE_TASK]({ commit }, payload): Promise<Task> {
    try {
      const formData = new FormData()

      formData.set('project_id', payload.projectId)
      formData.set('type_id', payload.category)
      formData.set('title', payload.name)
      formData.set('notes', payload.description)
      formData.set('assignee_id', payload.assigneeId)
      // formData.set('download_document_ids[]', payload.downloadDocumentIds)
      // formData.set('document_ids[]', payload.documentIds)

      console.log(payload)

      if (payload.downloadDocumentIds?.length) {
        payload.downloadDocumentIds.forEach(id => {
          formData.append('download_document_ids[]', id)
        })
      }

      if (payload.documentIds?.length) {
        payload.documentIds.forEach(id => {
          formData.append('document_ids[]', id)
        })
      }


      if (payload.fileList?.length) {
        payload.fileList.forEach((file: ElUploadInternalFileDetailWrapper | ElUploadInternalFileDetail) => {
          if ((file as ElUploadInternalFileDetailWrapper).file) {
            formData.append('download_documents[]', (file as ElUploadInternalFileDetailWrapper).file.raw)
          } else if ((file as ElUploadInternalFileDetail).raw) {
            formData.append('download_documents[]', (file as ElUploadInternalFileDetail).raw)
          }
        })
      }

      const { data }: AxiosResponse<Task> = await http.post('tasks', formData)

      commit(TaskMutations.M_CREATE_TASK, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
