import { ActionTree } from 'vuex'
import { IRootState } from '@/store'
import { IProjectState } from './Interface'
import { ProjectActionTypes, ProjectMutationTypes } from './Types'
import http from '@/http'
import { ElUploadInternalFileDetail } from 'element-ui/types/upload'

export const actions: ActionTree<IProjectState, IRootState> = {
  async [ProjectActionTypes.A_FETCH_PROJECT_INFO_NATURAL]({ commit }, params): Promise<any> {
    try {
      const responseNatural = await http.get('natural-persons', {
        params: {
          project_id: params.projectId,
          count: 10,
          page: params.insidePage,
          search: params.search,
          status: params.status,
        },
      })

      commit(ProjectMutationTypes.M_FETCH_NATURAL_PERSONS, responseNatural.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_FETCH_PROJECT_INFO_LEGAL]({ commit }, params): Promise<any> {
    try {
      const responseLegal = await http.get('legal-persons', {
        params: {
          project_id: params.projectId,
          count: 10,
          page: params.insidePage,
          search: params.search,
        },
      })

      commit(ProjectMutationTypes.M_FETCH_LEGAL_PERSONS, responseLegal.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_ADD_INDIVIDUAL]({ commit }, person): Promise<any> {
    try {
      const formData = new FormData()
      formData.set('project_id', person.projectId)
      formData.set('full_name', person.fullName)
      formData.set('role_id', person.roleId)
      formData.set('position', person.position)
      formData.set('documentIds[]', person.documentIds)

      if (person.legalPersonId) {
        formData.set('legal-person-id', person.legalPersonId)
      }

      person.fileList.forEach((file: ElUploadInternalFileDetail) => {
        if (file.raw) {
          formData.append('documents[]', file.raw)
        }
      })

      const { data } = await http.post('natural-persons', formData)

      this.dispatch('usersModule/A_FETCH_NATURAL_PERSONS', { projectId: person.projectId, count: 10 }, { root: true })

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_EDIT_INDIVIDUAL]({ commit }, person): Promise<any> {
    try {
      const formData = new FormData()
      formData.set('project_id', person.projectId)
      formData.set('full_name', person.fullName)
      formData.set('role_id', person.roleId)
      formData.set('position', person.position)

      if (person.legalPersonId) {
        formData.set('legal-person-id', person.legalPersonId)
      }

      person.files.forEach((file: ElUploadInternalFileDetail) => {
        if (file.raw) {
          formData.append('documents[]', file.raw)
        }
      })

      const { data } = await http.post('natural-persons/' + person.id, formData)

      this.dispatch('usersModule/A_FETCH_NATURAL_PERSONS', { projectId: person.projectId, count: 10 }, { root: true })

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_ADD_LEGAL]({ commit }, payload): Promise<any> {
    try {
      await http.post('legal-persons', payload)

      commit('storeCommon/M_CHANGE_USER_ROLE_LEGAL', payload.roleId, { root: true })

      this.dispatch('usersModule/A_FETCH_LEGAL_PERSONS', { projectId: payload.projectId, count: 10 }, { root: true })
      this.dispatch('usersModule/A_FETCH_BY_ROLES_LEGAL_PERSONS', { projectId: payload.projectId }, { root: true })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_EDIT_LEGAL]({ commit }, payload): Promise<any> {
    try {
      const { data } = await http.put('legal-persons/' + payload.id, payload)

      this.dispatch('usersModule/A_FETCH_LEGAL_PERSONS', { projectId: payload.projectId, count: 10 }, { root: true })

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_CHANGE_USER]({ commit }, person): Promise<any> {
    try {
      const response = await http.put(`/natural-persons/${person.id}/is_active`, { is_active: person.is_active })

      commit(ProjectMutationTypes.M_CHANGE_USER, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectActionTypes.A_CREATE_OR_UPDATE_LEGAL]({ commit }, payload): Promise<any> {
    try {
      const { data } = await http.post('legal-persons/create-or-update', payload)

      this.dispatch('usersModule/A_FETCH_LEGAL_PERSONS', { projectId: payload.project_id, count: 10 }, { root: true })
      this.dispatch('usersModule/A_FETCH_BY_ROLES_LEGAL_PERSONS', { projectId: payload.project_id }, { root: true })

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
