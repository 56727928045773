import { Module } from 'vuex'

import { actions } from './actions'
import { mutations } from './mutations'

import { IRootState } from '@/store'
import { TeamState } from './interfaces'

export const state = (): TeamState => ({
  team: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },

  teamList: [],

  orders: [],
})

export const TeamModule: Module<TeamState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
