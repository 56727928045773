import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { actions } from './Action';
import { mutations } from './Mutation';
import { IWorksState } from './Interface';
import { IRootState } from '@/store';

export const worksNamespace = namespace('storeWorks');

Vue.use(Vuex);

class Works implements IWorksState {
  public works = [];
  public lastPage = null;
  public currentPage = null;

  public workStructs = [];
  public facts = [];
}

export const storeWorks: any = {
  namespaced: true,
  state: () => new Works(),
  actions,
  mutations,
} as Module<IWorksState, IRootState>;
