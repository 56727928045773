import { IProjectState, IPerson } from './Interface'
import { MutationTree } from 'vuex'
import { ProjectMutationTypes } from './Types'

export const mutations: MutationTree<IProjectState> = {
  [ProjectMutationTypes.M_FETCH_NATURAL_PERSONS](state, payload: any) {
    state.naturalPersons.personList = payload.data
    state.naturalPersons.lastPage = payload.meta.last_page
    state.naturalPersons.currentPage = payload.meta.current_page
  },

  [ProjectMutationTypes.M_FETCH_LEGAL_PERSONS](state, payload: any) {
    const data = payload.data.map(person => {
      return {
        ...person,
        role: person.roleInfo,
      }
    })
    state.legalPersons.personList = data
    state.legalPersons.lastPage = payload.meta.last_page
    state.legalPersons.currentPage = payload.meta.current_page
  },

  [ProjectMutationTypes.M_CHANGE_USER](state, payload: any) {
    const indexUser = state.naturalPersons.personList.findIndex(item => item.id === payload.id)
    // const user: IPerson = {
    //   fullName: payload.full_name,
    //   id: payload.id,
    //   isActive: payload.is_active ? 'active' : 'not_active',
    //   position: payload.position,
    //   role: payload.role,
    // }
    state.naturalPersons.personList.splice(indexUser, 1, payload)
  },
}
