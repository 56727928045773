import { MutationTree } from 'vuex'

import { Paginate } from '../interfaces'
import { UserMutationTypes } from '../user/Types'
import { UsersState, User, NaturalPerson, LegalPerson } from './interfaces'

import { UsersMutations } from './types'

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.M_SET_USERS](state, payload: Paginate<User>) {
    state.users = payload
    state.userList.push(...payload.data)
  },

  [UsersMutations.M_SET_USER](state, payload: User) {
    state.user = payload
  },

  [UsersMutations.M_UPDATE_USER](state, payload: User) {
    const users = state.users.data.map(u => {
      if (u.id === payload.id) {
        u.isActive = payload.isActive
      }

      return u
    })

    state.users.data = users
  },

  [UsersMutations.M_FETCH_USER_ROLES](state, payload: any) {
    state.roles = payload
  },

  [UsersMutations.M_FETCH_NATURAL_PERSONS](state, payload: Paginate<NaturalPerson>) {
    state.naturalPersons = payload
    state.naturalPersonList.push(...payload.data)
  },

  [UsersMutations.M_FETCH_LEGAL_PERSONS](state, payload: Paginate<LegalPerson>) {
    state.legalPersons = payload
  },

  [UsersMutations.M_FETCH_BY_ROLES_LEGAL_PERSONS](state, payload: LegalPerson[]) {
    state.legals = payload
  },

  [UsersMutations.M_UPDATE_LEGAL_PERSON](state, payload: LegalPerson) {
    const legals = state.legals.map(l => {
      if (l.id === payload.id) {
        l = payload
      }

      return l
    })

    state.legals = legals
  },

  [UsersMutations.M_TOGGLE_PERSON_ACTIVE](state, payload: NaturalPerson) {
    const persons = state.naturalPersons.data.map(p => {
      if (p.id === payload.id) {
        p.isActive = payload.isActive
      }

      return p
    })

    state.naturalPersons.data = persons
  },

  [UsersMutations.M_CLEAR_USERS](state) {
    state.users = {
      data: [],
      meta: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    }
    state.userList = []
  },
}
