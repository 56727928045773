import { MutationTree } from 'vuex'

import { TaskMutations } from './types'

import { TaskState, Task } from './interfaces'
import { Paginate } from '../interfaces'

export const mutations: MutationTree<TaskState> = {
  [TaskMutations.M_FETCH_TASKS](state, payload: Paginate<Task>) {
    state.tasks = payload
    state.taskList.push(...payload.data)
  },

  [TaskMutations.M_CREATE_TASK](state, payload: Task) {
    state.tasks.data = [payload, ...state.tasks.data]
  },

  [TaskMutations.M_DELETE_TASK](state, payload: number) {
    state.taskList = state.taskList.filter(t => t.id !== payload)
  },

  [TaskMutations.M_CLEAR_TASKS](state) {
    state.tasks = {
      data: [],
      meta: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    }

    state.taskList = []
  },
}
