import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { actions } from './Action';
import { mutations } from './Mutation';
import { getters } from './Getters';
import { IUserState, IUser } from './Interface';
import { IRootState } from '@/store';

export const userNamespace = namespace('storeUser');

Vue.use(Vuex);

class User implements IUserState {
  public user = {
    id: 1,
    login: '',
    name: '',
    role: '',
    status: '',
    createdAt: null,
    updatedAt: null,
    email: '',
    projects: [],
  };

  public selectedProject = 0;
  public documentStatusesForProject = []

  public tasks = {
    taskList: [],
    lastPage: 0,
    currentPage: 0,
  };

  public complete = 0;
}

export const storeUser: any = {
  namespaced: true,
  state: () => new User(),
  actions,
  mutations,
  getters,
} as Module<IUserState, IRootState>;
