import { ActionTree } from 'vuex'
import http from '@/http'
import { IRootState } from '@/store'
import { Project, ProjectsState } from './interfaces'
import { ProjectsActions, ProjectsMutations } from './types'
import { RequestParams } from '@store/interfaces'
import { AxiosResponse } from 'axios'
import { Paginate } from '../interfaces'
import { UserMutationTypes } from '../user/Types'

export const actions: ActionTree<ProjectsState, IRootState> = {
  async [ProjectsActions.A_GET_PROJECTS]({ commit }, params: RequestParams): Promise<Paginate<Project>> {
    try {
      const { data }: AxiosResponse<Paginate<Project>> = await http.get('projects', { params })

      commit(ProjectsMutations.M_SET_PROJECTS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectsActions.A_GET_PROJECT]({ commit }, projectId: number): Promise<Project> {
    try {
      const { data }: AxiosResponse<Project> = await http.get('projects/' + projectId)

      commit(ProjectsMutations.M_SET_PROJECT, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectsActions.A_CREATE_PROJECT]({ commit }, project: Project): Promise<Project> {
    try {
      const { data }: AxiosResponse<Project> = await http.post('projects', project)

      // commit(ProjectsMutations.M_SET_PROJECT, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectsActions.A_UPDATE_PROJECT]({ commit, getters }, project: Project): Promise<Project> {
    try {
      const { data }: AxiosResponse<Project> = await http.put('projects/' + project.id, project)

      commit('storeUser/M_UPDATE_PROJECT', data, { root: true })
      getters['storeUser/project']

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectsActions.A_CONNECT_PROJECT_TO_USER](
    { commit },
    payload: { userId: number; projectId: number; roleId: number },
  ): Promise<void> {
    try {
      await http.post('user-project', payload)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [ProjectsActions.A_DISCONNECT_PROJECT_TO_USER](
    { commit },
    payload: { userId: number; projectId: number; roleId: number },
  ): Promise<void> {
    try {
      await http.delete('user-project', { data: payload })
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
