import {IDocumentsState, IDocumentHistory, IDocumentKit} from './Interface'
import { MutationTree } from 'vuex'
import Vue from 'vue'
import { DocumentsMutationTypes } from './Types'
import moment from 'moment'
import { findIndex } from 'lodash'

const mutateItem = (element: any) => {
  if (element.type === 'document') {
    return {
      ...element,
      createdAt: moment(element.createdAt).format('DD.MM.YYYY'),
      updatedAt: moment(element.updatedAt).format('DD.MM.YYYY'),
    }
  } else {
    return {
      ...element,
      date: moment(element.date).format('DD.MM.YYYY'),
      content: element.content.map(subElement => ({
        ...mutateItem(subElement),
      })),
    }
  }
}

export const mutations: MutationTree<IDocumentsState> = {
  [DocumentsMutationTypes.M_DOCUMENTS_INCOMING](state, payload: any) {
    const data = payload.data.map(value => {
      return {
        ...value,
        createdAt: moment(value.createdAt).format('DD.MM.YYYY'),
        updatedAt: moment(value.updatedAt).format('DD.MM.YYYY'),
      }
    })

    if (state.documentsIncoming.documentList.length) {
      state.documentsIncoming.documentList = state.documentsIncoming.documentList.concat(data)
    } else {
      state.documentsIncoming.documentList = data
    }
    state.documentsIncoming.lastPage = payload.meta.lastPage
    state.documentsIncoming.currentPage = payload.meta.currentPage
    state.documentsIncoming.total = payload.meta.total
  },

  [DocumentsMutationTypes.M_DOCUMENTS_EXECUTIVE](state, payload: any) {
    const data = payload.data.map(value => {
      return {
        ...value,
        createdAt: moment(value.createdAt).format('DD.MM.YYYY'),
        updatedAt: moment(value.updatedAt).format('DD.MM.YYYY'),
      }
    })

    if (state.documentsExecutive.documentList.length) {
      state.documentsExecutive.documentList = state.documentsExecutive.documentList.concat(data)
    } else {
      state.documentsExecutive.documentList = data
    }
    state.documentsExecutive.lastPage = payload.meta.lastPage
    state.documentsExecutive.currentPage = payload.meta.currentPage
    state.documentsExecutive.total = payload.meta.total
  },

  [DocumentsMutationTypes.M_FETCH_DOCUMENTS_BY_VOLUME](state, payload: any) {
    state.documentsByVolume = payload.data.map(item => ({
      ...mutateItem(item),
    }))
  },

  [DocumentsMutationTypes.M_CHANGE_STATUS](state, payload: any) {
    let index = -1
    Object.keys(state.documentsByVolume).forEach(key => {
      index = findIndex(state.documentsByVolume[key], document => Number(document.id) === Number(payload.id))
      if (index !== -1) {
        Vue.set(state.documentsByVolume[key], index, payload)
        index = -1
      }
    })
  },

  [DocumentsMutationTypes.M_FETCH_DOCUMENTS_STATUS](state, payload: any) {
    Vue.set(state, 'documentStatus', payload)
  },

  [DocumentsMutationTypes.M_DOCUMENTS_ALL](state, payload: any) {
    const data = payload.data.map(value => {
      return {
        ...value,
        createdAt: moment(value.createdAt).format('DD.MM.YYYY'),
        updatedAt: moment(value.updatedAt).format('DD.MM.YYYY'),
      }
    })
    state.documentsAll.documentList = data
    state.documentsAll.lastPage = payload.meta.lastPage
    state.documentsAll.perPage = payload.meta.perPage
    state.documentsAll.currentPage = payload.meta.currentPage
    state.documentsAll.total = payload.meta.total
  },

  [DocumentsMutationTypes.M_DOCUMENTS_TYPES](state, payload: any) {
    state.documentTypes = payload
  },

  [DocumentsMutationTypes.M_DOCUMENTS_BY_TYPES](state, payload: any) {
    const data = payload.data.data.map(value => {
      return {
        ...value,
        createdAt: moment(value.createdAt).format('DD.MM.YYYY'),
        updatedAt: moment(value.updatedAt).format('DD.MM.YYYY'),
      }
    })

    if (state.documentByTypes[payload.category]) {
      state.documentByTypes[payload.category].documentList = state.documentByTypes[
        payload.category
      ].documentList.concat(data)
      state.documentByTypes[payload.category].lastPage = payload.lastPage
      state.documentByTypes[payload.category].currentPage = payload.currentPage
      state.documentByTypes[payload.category].total = payload.total
    } else {
      Vue.set(state.documentByTypes, payload.category, {
        documentList: data,
        lastPage: payload.data.meta.lastPage,
        currentPage: payload.data.meta.currentPage,
        total: payload.data.meta.current_page.total,
      })
    }
  },

  [DocumentsMutationTypes.M_DOCUMENTS_UPLOAD](state, payload: any) {
    // const data = {
    //   comment: payload.data.comment,
    //   createdAt: moment(payload.data.created_at).format('DD.MM.YYYY'),
    //   filename: payload.data.filename,
    //   id: payload.data.id,
    //   typeId: payload.data.typeId,
    //   updatedAt: moment(payload.data.updated_at).format('DD.MM.YYYY'),
    //   downloadLink: payload.data.downloadLink,
    //   viewLink: payload.data.viewLink,
    //   cipher: payload.data.codeWd,
    //   mark: payload.data.mark,
    // }

    // state.documentByTypes[payload.category.bpm_entity_name].documentList.push(data)
    state.documentsAll.documentList = [
      payload,
      ...state.documentsAll.documentList,
    ]
    // state.documentCountNew++
  },

  [DocumentsMutationTypes.M_CLEAR_DOCUMENTS_BY_TYPES](state, type: any) {
    if (type) {
      state.documentByTypes[type.bpm_entity_name] = {
        documentList: [],
        lastPage: 1,
        currentPage: 1,
        total: 0,
      }
    } else {
      state.documentByTypes = []
    }
  },

  [DocumentsMutationTypes.M_CLEAR_DOCUMENTS_EXECUTIVE](state) {
    state.documentsExecutive = {
      documentList: [],
      lastPage: 1,
      currentPage: 1,
      total: 0,
    }
  },

  [DocumentsMutationTypes.M_SET_DOCUMENTS_COUNT_NEW](state, payload: number) {
    state.documentCountNew = payload
  },

  [DocumentsMutationTypes.M_SET_DOCUMENTS_EXEC_COUNT_NEW](state, payload: number) {
    state.documentExecCountNew = payload
  },

  [DocumentsMutationTypes.M_DOCUMENT_HISTORY](state, payload: IDocumentHistory[]) {
    state.documentHistory = payload
  },

  // [DocumentsMutationTypes.M_DOCUMENT_READ](state, documentId: number) {
  //   const documents = state.documentsAll.documentList.map(d => {
  //     if (d.id === documentId) {
  //       d.isNew = false
  //     }

  //     return d
  //   })

  //   state.documentsAll.documentList = [
  //     ...documents,
  //   ]
  //   state.documentCountNew--
  // },


  [DocumentsMutationTypes.M_DOCUMENT_READ](state, { documentId = -2, documentIds = [] }: { documentId: number, documentIds: number[] }) {
    let documents = state.documentsAll.documentList
    if (documentId > -2) {
      documents = state.documentsAll.documentList.map(d => {
        if (d.id === documentId) {
          d.isNew = false
        }

        return d
      })
    } else if (documentIds.length > 0) {
      documents = state.documentsAll.documentList.map(d => {
        documentIds.forEach(id => {
          if (d.id === id) {
            d.isNew = false
          }
        })

        return d
      })
    }
    state.documentsAll.documentList = [
      ...documents,
    ]
    state.documentCountNew--
  },

  [DocumentsMutationTypes.M_DOCUMENT_KITS_ALL](state, payload: IDocumentKit[]) {
    state.documentKits = payload
  },
}
