export const enum ProjectsActions {
  A_GET_PROJECTS = 'A_GET_PROJECTS',
  A_GET_PROJECT = 'A_GET_PROJECT',

  A_CREATE_PROJECT = 'A_CREATE_PROJECT',
  A_UPDATE_PROJECT = 'A_UPDATE_PROJECT',
  A_DELETE_PROJECT = 'A_DELETE_PROJECT',

  A_CONNECT_PROJECT_TO_USER = 'A_CONNECT_PROJECT_TO_USER',
  A_DISCONNECT_PROJECT_TO_USER = 'A_DISCONNECT_PROJECT_TO_USER',
}

export const enum ProjectsMutations {
  M_SET_PROJECTS = 'M_SET_PROJECTS',
  M_SET_PROJECT = 'M_SET_PROJECT',

  M_CLEAR_PROJECTS = 'M_CLEAR_PROJECTS',
}
