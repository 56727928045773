export const enum ProjectActionTypes {
  A_FETCH_PROJECT_INFO_NATURAL = 'A_FETCH_PROJECT_INFO_NATURAL',
  A_FETCH_PROJECT_INFO_LEGAL = 'A_FETCH_PROJECT_INFO_LEGAL',
  A_ADD_LEGAL = 'A_ADD_LEGAL',
  A_EDIT_LEGAL = 'A_EDIT_LEGAL',
  A_CREATE_OR_UPDATE_LEGAL = 'A_CREATE_OR_UPDATE_LEGAL',
  A_ADD_INDIVIDUAL = 'A_ADD_INDIVIDUAL',
  A_EDIT_INDIVIDUAL = 'A_EDIT_INDIVIDUAL',
  A_CHANGE_USER = 'A_CHANGE_USER',
}
export const enum ProjectMutationTypes {
  M_FETCH_PROJECT_INFO = 'M_FETCH_PROJECT_INFO',
  M_FETCH_NATURAL_PERSONS = 'M_FETCH_NATURAL_PERSONS',
  M_FETCH_LEGAL_PERSONS = 'M_FETCH_LEGAL_PERSONS',
  M_ADD_INDIVIDUAL = 'M_ADD_INDIVIDUAL',
  M_CHANGE_USER = 'M_CHANGE_USER',
}
