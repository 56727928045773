import Vue from 'vue'
import Vuex from 'vuex'

import { ICommonState } from '@store/common/Interface'
import { IDocumentsState } from '@store/documents/Interface'
import { IProjectState } from '@store/project/Interface'
import { IUserState } from '@store/user/Interface'
import { IWorksState } from '@store/works/Interface'
import { storeCommon } from '@store/common'
import { storeDocuments } from '@store/documents'
import { storeProject } from '@store/project'
import { ProjectsModule } from '@store/projects'
import { storeUser } from '@store/user'
import { TeamModule } from '@store/team'
import { UsersModule } from '@store/users'
import { storeWorks } from '@store/works'
import { TaskModule } from '@store/task'

Vue.use(Vuex)

export interface IRootState {
  storeCommon: ICommonState
  storeDocuments: IDocumentsState
  storeProject: IProjectState
  storeUser: IUserState
  storeWorks: IWorksState
  version: string
}

class RootState implements IRootState {
  public storeCommon = storeCommon
  public storeDocuments = storeDocuments
  public storeProject = storeProject
  public storeUser = storeUser
  public storeWorks = storeWorks
  public version = '3.0.0'
}

export default new Vuex.Store<IRootState>({
  state: new RootState(),
  modules: {
    storeCommon,
    storeDocuments,
    storeProject,
    projectsModule: ProjectsModule,
    storeUser,
    usersModule: UsersModule,
    storeWorks,
    teamModule: TeamModule,
    taskModule: TaskModule,
  },
})
