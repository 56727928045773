import { ActionTree } from 'vuex'
import http from '@/http'
import { AxiosResponse } from 'axios'
import { IRootState } from '@/store'
import {FromUser, LegalPerson, NaturalPerson, User, UsersState, Log, ProjectUser} from './interfaces'
import { UsersActions, UsersMutations } from './types'
import { RequestParams } from '@store/interfaces'
import { Paginate } from '../interfaces'

export const actions: ActionTree<UsersState, IRootState> = {
  async [UsersActions.A_GET_USERS]({ commit }, params: RequestParams): Promise<Paginate<User>> {
    try {
      const { data }: AxiosResponse<Paginate<User>> = await http.get('users', { params })

      commit(UsersMutations.M_SET_USERS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_GET_USERS_FOR_PROJECT]({ commit }, projectId: number): Promise<ProjectUser[]> {
    try {
      const { data } = await http.get('projects/' + projectId + '/users')
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_GET_USER]({ commit }, userId: number): Promise<User> {
    try {
      const { data }: AxiosResponse<User> = await http.get('users/' + userId)

      commit(UsersMutations.M_SET_USER, data)

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_CREATE_USER]({ commit }, payload: FromUser): Promise<Paginate<User>> {
    try {
      const { data }: AxiosResponse<Paginate<User>> = await http.post('users', payload)

      // commit(UsersMutations.M_SET_USERS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_UPDATE_USER]({ commit }, payload: User): Promise<Paginate<User>> {
    try {
      const { data }: AxiosResponse<Paginate<User>> = await http.put('users/' + payload.id, payload)

      commit(UsersMutations.M_UPDATE_USER, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_DELETE_USER](): Promise<void> {
    try {
      await http.delete('users')
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_CHANGE_PASSWORD_USER](
    { commit },
    payload: { userId: number; password: string; passwordConfirmation: string },
  ): Promise<void> {
    try {
      await http.put(`users/${payload.userId}/password`, payload)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_FETCH_USER_ROLES]({ commit }): Promise<any> {
    try {
      const { data } = await http.get('users/roles')

      commit(UsersMutations.M_FETCH_USER_ROLES, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_FETCH_NATURAL_PERSONS]({ commit }, params: RequestParams): Promise<Paginate<NaturalPerson>> {
    try {
      const { data }: AxiosResponse<Paginate<NaturalPerson>> = await http.get('natural-persons', { params })

      commit(UsersMutations.M_FETCH_NATURAL_PERSONS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_FETCH_FILTERED_NATURAL_PERSONS](
    { commit },
    params: RequestParams,
  ): Promise<Paginate<NaturalPerson>> {
    try {
      const { data }: AxiosResponse<Paginate<NaturalPerson>> = await http.get('natural-persons', { params })

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_FETCH_LEGAL_PERSONS]({ commit }, params: RequestParams): Promise<Paginate<LegalPerson>> {
    try {
      const { data }: AxiosResponse<Paginate<LegalPerson>> = await http.get('legal-persons', { params })

      commit(UsersMutations.M_FETCH_LEGAL_PERSONS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_FETCH_FILTERED_LEGAL_PERSONS](
    { commit },
    params: RequestParams,
  ): Promise<Paginate<LegalPerson>> {
    try {
      const { data }: AxiosResponse<Paginate<LegalPerson>> = await http.get('legal-persons', { params })

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_FETCH_BY_ROLES_LEGAL_PERSONS](
    { commit },
    params: RequestParams,
  ): Promise<Paginate<LegalPerson>> {
    try {
      const { data }: AxiosResponse<Paginate<LegalPerson>> = await http.get('legal-persons-by-roles', { params })

      commit(UsersMutations.M_FETCH_BY_ROLES_LEGAL_PERSONS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_UPDATE_LEGAL_PERSON]({ commit }, payload: LegalPerson): Promise<LegalPerson> {
    try {
      const { data }: AxiosResponse<LegalPerson> = await http.put(`legal-persons/${payload.id}`, payload)

      commit(UsersMutations.M_UPDATE_LEGAL_PERSON, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_TOGGLE_PERSON_ACTIVE]({ commit }, payload: NaturalPerson): Promise<NaturalPerson> {
    try {
      const { data } = await http.put(`natural-persons/${payload.id}/is_active`, { is_active: !payload.isActive })

      commit(UsersMutations.M_TOGGLE_PERSON_ACTIVE, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_CONNECT_NATURAL_PERSON]({ commit }, payload: NaturalPerson): Promise<NaturalPerson> {
    try {
      const { data }: AxiosResponse<NaturalPerson> = await http.put(`natural-persons/${payload.id}`, payload)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_DISCONNECT_NATURAL_PERSON]({ commit }, payload: NaturalPerson): Promise<NaturalPerson> {
    try {
      const { data }: AxiosResponse<NaturalPerson> = await http.put(`natural-persons/${payload.id}`, payload)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_CONNECT_USER_TO_PROJECT](
    { commit },
    payload: { userId: number; projectId: number; roleId: number },
  ): Promise<void> {
    try {
      await http.post('user-project', payload)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_DISCONNECT_USER_TO_PROJECT](
    { commit },
    payload: { userId: number; projectId: number; roleId: number },
  ): Promise<void> {
    try {
      await http.delete('user-project', { data: payload })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_GET_LOGS]({ commit }, { page, user_id }): Promise<Log[]> {
    try {
      const response = await http.get('/logs/' + user_id, {params: {page}})
      return response?.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UsersActions.A_CHANGE_ACCESS_USER_STATUSES]({ commit }, {project_id, user_id, status_ids }): Promise<void> {
    try {
      await http.post(`projects/${project_id}/document-status/${user_id}`, {status_ids});
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
