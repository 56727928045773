import { Module } from 'vuex'

import { actions } from './actions'
import { mutations } from './mutations'

import { IRootState } from '@/store'
import { TaskState } from './interfaces'

export const state = (): TaskState => ({
  tasks: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
  },

  taskList: [],
})

export const TaskModule: Module<TaskState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
}
