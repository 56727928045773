import { MutationTree } from 'vuex'
import { Paginate } from '../interfaces'
import { Project } from './interfaces'

import { ProjectsState } from './interfaces'
import { ProjectsMutations } from './types'

export const mutations: MutationTree<ProjectsState> = {
  [ProjectsMutations.M_SET_PROJECTS](state, payload: Paginate<Project>) {
    state.projects = payload
    state.projectList.push(...payload.data)
  },

  [ProjectsMutations.M_SET_PROJECT](state, payload: Project) {
    state.project = payload
  },

  [ProjectsMutations.M_CLEAR_PROJECTS](state) {
    state.projects = {
      data: [],
      meta: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    }
    state.projectList = []
  },
}
