import { IUserState, ITaskList } from './Interface'
import { MutationTree } from 'vuex'
import { UserMutationTypes } from './Types'
import { ITask } from './Interface'
import moment from 'moment'
import { Project } from '../projects/interfaces'
import {IStatusDocument} from '@store/documents/Interface';

export const mutations: MutationTree<IUserState> = {
  [UserMutationTypes.M_LOGIN](state, payload: any) {
    state.user = payload
  },

  [UserMutationTypes.M_SELECT_PROJECT](state, payload: number) {
    localStorage.setItem('projectId', payload.toString())
    state.selectedProject = payload
  },

  [UserMutationTypes.M_FETCH_TASKS](state, payload: any) {
    const tasks = payload.data.map(task => {
      return {
        ...task,
        createdAt: moment(task.createdAt).format('DD.MM.YYYY'),
        updatedAt: moment(task.updatedAt).format('DD.MM.YYYY'),
      }
    })

    if (state.tasks.taskList.length) {
      state.tasks.taskList = state.tasks.taskList.concat(tasks)
    } else {
      state.tasks.taskList = tasks
    }
    state.tasks.lastPage = payload.meta.lastPage
    state.tasks.currentPage = payload.meta.currentPage
  },

  [UserMutationTypes.M_LOGOUT](state, payload: any) {
    Object.keys(state.user).map(key => {
      state.user[key] = ''
    })
  },

  [UserMutationTypes.M_FETCH_COMPLETE](state, payload: any) {
    state.complete = payload.total_complete
  },

  [UserMutationTypes.M_ADD_TASK](state, payload: any) {
    state.tasks.taskList.push({
      createdAt: moment(payload.createdAt).format('DD.MM.YYYY'),
      id: payload.id,
      title: payload.title,
      type: payload.type,
      updatedAt: moment(payload.updatedAt).format('DD.MM.YYYY'),
      typeInfo: payload.typeInfo,
      comment: payload.comment,
      notes: payload.notes,
      statusCode: payload.statusCode,
    })
  },

  [UserMutationTypes.M_CLEAR_TASK](state, payload: any) {
    state.tasks = {
      taskList: [],
      lastPage: 0,
      currentPage: 0,
    }
  },

  [UserMutationTypes.M_UPDATE_PROJECT](state, payload: Project) {
    const projects = state.user.projects.map((p) => {
      if (p.id === payload.id) {
        p = payload
      }

      return p
    })

    state.user.projects = [
      ...projects,
    ]
  },

  [UserMutationTypes.M_CHANGE_ACCESS_USER_STATUSES](state, payload: IStatusDocument[]){
    state.documentStatusesForProject = payload
  }
}
