import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { actions } from './Action';
import { mutations } from './Mutation';
import { ICommonState, IMark } from './Interface';
import { IRootState } from '../../store';

export const commonNamespace = namespace('storeCommon');

Vue.use(Vuex);

class Common implements ICommonState {
  public marks = [];
  public codes = [];
  public isAuthUser = false;
  public userToken = null;
  public typeTask = [];
  public rolesNatural = [];
  public rolesLegal = [];
}

export const storeCommon: any = {
  namespaced: true,
  state: () => new Common(),
  actions,
  mutations,
} as Module<ICommonState, IRootState>;
