import { ActionTree } from 'vuex'
import http from '@/http'
import { IRootState } from '@/store'
import { IUserState } from './Interface'
import { UserActionTypes, UserMutationTypes } from './Types'

export const actions: ActionTree<IUserState, IRootState> = {
  async [UserActionTypes.A_LOGIN]({rootState}, auth): Promise<any> {
    try {
      const response = await http.post('auth/login', {
        email: auth.email,
        password: auth.password,
      })

      localStorage.setItem('token', response.data.accessToken)

      await this.dispatch('storeUser/A_FETCH_USER', true, { root: true })

      http.post('/logs', {id: rootState.storeUser.user?.id, name: rootState.storeUser.user?.name, type: 'login', res:'success'})
    } catch (error) {
      return Promise.reject(error)
    }
  },

  [UserActionTypes.A_LOGOUT]({ commit }): void {
    http.get('auth/logout').then(() => {
      localStorage.removeItem('token')

      commit(UserMutationTypes.M_LOGOUT)
    })
  },

  async [UserActionTypes.A_FETCH_USER]({ commit, dispatch }): Promise<any> {
    try {
      const response = await http.get('account')

      const data = {
        id: response.data.id,
        name: response.data.fullName,
        role: response.data.isAdmin ? 'admin' : 'user',
        status: response.data.status,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
        projects: response.data.projects,
        email: response.data.email,
      }

      const projectId = localStorage.getItem('projectId')
      if (projectId) {
        const project = data.projects.find(item => item.id === parseInt(projectId, 10))
        if (project) {
          commit(UserMutationTypes.M_SELECT_PROJECT, project.id)
        } else if (data.projects.length) {
          commit(UserMutationTypes.M_SELECT_PROJECT, data.projects[0].id)
        }
      }

      commit(UserMutationTypes.M_LOGIN, data)

      dispatch(UserActionTypes.A_GET_ACCESS_USER_STATUSES, projectId)

    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UserActionTypes.A_FETCH_TASKS]({ commit }, params): Promise<any> {
    try {
      const response = await http.get('/tasks', { params: { project_id: params.projectId, page: params.currentPage } })

      commit(UserMutationTypes.M_FETCH_TASKS, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UserActionTypes.A_FETCH_COMPLETE]({ commit }, params): Promise<any> {
    try {
      const response = await http.get('works-total-complete', { params: { project_id: params.projectId } })

      commit(UserMutationTypes.M_FETCH_COMPLETE, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UserActionTypes.A_ADD_TASK]({ commit }, task): Promise<any> {
    try {
      const data = new FormData()

      data.set('project_id', task.projectId)
      data.set('type_id', task.category)
      data.set('title', task.name)
      data.set('notes', task.description)

      task.fileList.forEach(element => {
        data.append('documents[]', element.file.raw)
      })

      const response = await http.post('tasks', data)

      commit(UserMutationTypes.M_ADD_TASK, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UserActionTypes.A_FINISH_TASK_OUT](
    { commit },
    params: { selectedProject: number; taskID: number },
  ): Promise<void> {
    try {
      await http.put(`/tasks/${params.taskID}/finish`)

      commit(UserMutationTypes.M_CLEAR_TASK)

      this.dispatch('storeUser/A_FETCH_TASKS', { projectId: params.selectedProject })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UserActionTypes.A_FINISH_TASK_IN]({ commit }, task): Promise<void> {
    try {
      const formData = new FormData()

      formData.set('result', task.result)
      formData.set('documentIds[]', task.documentIds)

      task.fileList.forEach(file => {
        if (file.raw) {
          formData.append('documents[]', file.raw)
        }
      })

      await http.post(`/tasks/${task.id}/finish`, formData)

      commit('taskModule/M_DELETE_TASK', task.id, { root: true })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [UserActionTypes.A_CHANGE_PROJECT]({ state, commit, dispatch }, projectId: number): Promise<void> {
    commit(UserMutationTypes.M_SELECT_PROJECT, projectId)

    this.dispatch('storeDocuments/A_FETCH_DOCUMENTS_COUNT_NEW')
    this.dispatch('storeDocuments/A_FETCH_DOCUMENTS_EXEC_COUNT_NEW')
    if(state.selectedProject){
      dispatch(UserActionTypes.A_GET_ACCESS_USER_STATUSES, projectId)
    }
  },

  async [UserActionTypes.A_GET_ACCESS_USER_STATUSES]({ state, commit }, projectId: number): Promise<void> {
    const { data } = await http.get(`projects/${projectId}/document_status/${state.user.id}`)
    commit(UserMutationTypes.M_CHANGE_ACCESS_USER_STATUSES, data)
  }
}
