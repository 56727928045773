import { ActionTree } from 'vuex'
import http from '@/http'
import { IRootState } from '@/store'
import { Invite, Order, Team, TeamState } from './interfaces'
import { TeamActions, TeamMutations } from './types'
import { AxiosResponse } from 'axios'
import { Paginate } from '../interfaces'

export const actions: ActionTree<TeamState, IRootState> = {
  async [TeamActions.A_FETCH_TEAM]({ commit }, payload): Promise<Paginate<Team>> {
    try {
      const { data }: AxiosResponse<Paginate<Team>> = await http.get('assignees', {
        params: {
          project_id: payload.projectId,
          page: payload.page,
        },
      })
      /* tslint:disable-next-line */
      if (payload.page <= 1 || !payload.page) {
        commit(TeamMutations.M_FETCH_TEAM, data)
      } else {
        commit(TeamMutations.M_FETCH_TEAM, data)
      }

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [TeamActions.A_FETCH_ORDERS]({ commit }, payload): Promise<Order[]> {
    try {
      const { data }: AxiosResponse<Order[]> = await http.get('orders', {
        params: {
          project_id: payload.projectId,
        },
      })

      commit(TeamMutations.M_FETCH_ORDERS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [TeamActions.A_CREATE_ORDER]({ commit }, payload: Order): Promise<Order> {
    try {
      const { data }: AxiosResponse<Order> = await http.post('orders', payload)

      // commit(TeamMutations.M_FETCH_ORDER, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [TeamActions.A_INVITE_SPECIALIST]({ commit }, payload): Promise<void> {
    try {
      const { data }: AxiosResponse<void> = await http.post('invite', payload)

      // commit(TeamMutations.M_FETCH_ORDERS, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [TeamActions.A_DISABLE_SPECIALIST]({ commit }, id: number): Promise<void> {
    try {
      await http.delete('assignees/' + id)

      commit(TeamMutations.M_DISABLE_SPECIALIST, id)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
