import {GetterTree} from 'vuex';
import {UsersState} from '@store/users/interfaces';
import {IRootState} from '@/store';
import {Role} from '@store/common/Interface';

export const getters: GetterTree<UsersState, IRootState> = {
  rolesMap(state){
    const map:  { [key: number]: string } = {}

    state.roles.forEach((role: Role) => {
      map[role.id] = role.name
    })

    return map
  }
}
