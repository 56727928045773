export const enum WorksActionTypes {
  A_WORKS = 'A_WORKS',
  A_FETCH_WORK_STRUCTS = 'A_FETCH_WORK_STRUCTS',
  A_ADD_WORK_STRUCT = 'A_ADD_WORK_STRUCT',
  A_UPDATE_WORK_STRUCT = 'A_UPDATE_WORK_STRUCT',
  A_DELETE_WORK_STRUCT = 'A_DELETE_WORK_STRUCT',
  A_CAN_DELETE_WORK_STRUCT = 'A_CAN_DELETE_WORK_STRUCT',
  A_FETCH_FACT = 'A_FETCH_FACT',
  A_ADD_FACT = 'A_ADD_FACT',
  A_ADD_MULTI_FACT = 'A_ADD_MULTI_FACT',
  A_ADD_SEND_FORMATION = 'A_ADD_SEND_FORMATION',
}
export const enum WorksMutationTypes {
  M_WORKS = 'M_WORKS',
  M_FETCH_WORK_STRUCTS = 'M_FETCH_WORK_STRUCTS',
  M_SET_ACTIVE_WORK_STRUCT_CHILDREN = 'M_SET_ACTIVE_WORK_STRUCT_CHILDREN',
  M_ADD_WORK_STRUCT = 'M_ADD_WORK_STRUCT',
  M_UPDATE_WORK_STRUCT = 'M_UPDATE_WORK_STRUCT',
  M_DELETE_WORK_STRUCT = 'M_DELETE_WORK_STRUCT',
  M_FETCH_FACT = 'M_FETCH_FACT',
  M_ADD_FACT = 'M_ADD_FACT',
  M_ADD_MULTI_FACT = 'M_ADD_MULTI_FACT',
  M_ADD_SEND_FORMATION = 'M_ADD_SEND_FORMATION',
}
