import {IWorksState, IFact, IWorkStruct} from './Interface'
import { MutationTree } from 'vuex'
import { WorksMutationTypes } from './Types'
import moment from 'moment'
import Vue from 'vue';

export const mutations: MutationTree<IWorksState> = {
  [WorksMutationTypes.M_WORKS](state, payload: any) {
    state.works = payload.data.map(item => {
      return {
        completePercent: item.completePercent,
        createdAt: moment(item.createdAt).format('DD.MM.YYYY'),
        id: item.id,
        wdMark: item.mark,
        measure: item.measure,
        name: item.name,
        updatedAt: item.updatedAt,
        volume: item.volume,
        executiveDocumentationCount: item.executiveDocumentationCount,
        recommendation: item.recommendation,
      }
    })
    state.lastPage = payload.meta.lastPage
    state.currentPage = payload.meta.currentPage
  },

  [WorksMutationTypes.M_FETCH_FACT](state, payload: IFact[]) {
    state.facts = payload.map(fact => {
      return {
        ...fact,
        createdAt: moment(fact.createdAt).format('DD.MM.YYYY'),
      }
    })
  },

  [WorksMutationTypes.M_FETCH_WORK_STRUCTS](state, payload: IWorkStruct[]) {
    state.workStructs = payload
  },

  [WorksMutationTypes.M_ADD_WORK_STRUCT](state, payload: IWorkStruct) {
    state.workStructs.push(payload)
  },

  [WorksMutationTypes.M_UPDATE_WORK_STRUCT](state, payload: IWorkStruct) {
    const index = state.workStructs.findIndex((el) => el.id === payload.id)

    Vue.set(state.workStructs, index, payload)
  },

  [WorksMutationTypes.M_DELETE_WORK_STRUCT](state, id: number) {
    state.workStructs = state.workStructs.filter((workStruct) => workStruct.id !== id)
  },
}
