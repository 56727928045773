import { MutationTree } from 'vuex'

import { TeamMutations } from './types'

import { TeamState, Team, Orders } from './interfaces'
import { Paginate } from '../interfaces'

export const mutations: MutationTree<TeamState> = {
  [TeamMutations.M_FETCH_TEAM](state, payload: Paginate<Team>) {
    state.team = payload

    if (payload.meta.currentPage === 1) {
      state.teamList = payload.data
    } else {
      state.teamList.push(...payload.data)
    }
  },

  [TeamMutations.M_CLEAR_TEAM](state) {
    state.team = {
      data: [],
      meta: {
        currentPage: 0,
        lastPage: 0,
        perPage: 0,
        total: 0,
      },
    }

    state.teamList = []
  },

  [TeamMutations.M_FETCH_ORDERS](state, payload: Orders[]) {
    state.orders = payload
  },

  [TeamMutations.M_DISABLE_SPECIALIST](state, payload: number) {
    state.teamList = state.teamList.filter(t => t.id === payload)
  }
}
