export const enum TaskActions {
  A_FETCH_TASKS = 'A_FETCH_TASKS',
  A_FETCH_COMPLETED_TASKS = 'A_FETCH_COMPLETED_TASKS',
  A_CREATE_TASK = 'A_CREATE_TASK',
  A_FINISH_TASK = 'A_FINISH_TASK',
}

export const enum TaskMutations {
  M_FETCH_TASKS = 'M_FETCH_TASKS',
  M_FETCH_COMPLETED_TASKS = 'M_FETCH_COMPLETED_TASKS',
  M_CREATE_TASK = 'M_CREATE_TASK',
  M_DELETE_TASK = 'M_DELETE_TASK',
  M_FINISH_TASK = 'M_FINISH_TASK',

  M_CLEAR_TASKS = 'M_CLEAR_TASKS',
}
