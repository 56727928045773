import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { actions } from './Action';
import { mutations } from './Mutation';
import { IProjectState } from './Interface';
import { IRootState } from '../../store';

export const projectNamespace = namespace('storeProject');

Vue.use(Vuex);

class Project implements IProjectState {
  public naturalPersons = {
    personList: [],
    lastPage: 0,
    currentPage: 0,
  };

  public legalPersons = {
    personList: [],
    lastPage: 0,
    currentPage: 0,
  };
}

export const storeProject: any = {
  namespaced: true,
  state: () => new Project(),
  actions,
  mutations,
} as Module<IProjectState, IRootState>;
