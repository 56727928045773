export const enum DocumentsActionTypes {
  A_DOCUMENTS_INCOMING = 'A_DOCUMENTS_INCOMING',
  A_DOCUMENTS_EXECUTIVE = 'A_DOCUMENTS_EXECUTIVE',
  A_DOCUMENTS_ALL = 'A_DOCUMENTS_ALL',
  A_DOCUMENTS_TYPES = 'A_DOCUMENTS_TYPES',
  A_DOCUMENTS_BY_TYPES = 'A_DOCUMENTS_BY_TYPES',
  A_DOCUMENTS_UPLOAD = 'A_DOCUMENTS_UPLOAD',
  A_SIGN_UPLOAD = 'A_SIGN_UPLOAD',
  A_MAKE_LIST_WORKS = 'A_MAKE_LIST_WORKS',
  CLEAR_DOCUMENTS_BY_TYPES = 'CLEAR_DOCUMENTS_BY_TYPES',
  A_CLEAR_DOCUMENTS_EXECUTIVE = 'A_CLEAR_DOCUMENTS_EXECUTIVE',
  A_DELETE_DOCUMENT = 'A_DELETE_DOCUMENT',
  A_FETCH_DOCUMENTS_BY_VOLUME = 'A_FETCH_DOCUMENTS_BY_VOLUME',
  A_CHANGE_STATUS = 'A_CHANGE_STATUS',
  A_FETCH_DOCUMENTS_STATUS = 'A_FETCH_DOCUMENTS_STATUS',
  A_GET_DOCUMENTS_STATUS_FOR_PROJECT = 'A_GET_DOCUMENTS_STATUS_FOR_PROJECT',
  A_FETCH_DOCUMENTS_COUNT_NEW = 'A_FETCH_DOCUMENTS_COUNT_NEW',
  A_FETCH_DOCUMENTS_EXEC_COUNT_NEW = 'A_FETCH_DOCUMENTS_EXEC_COUNT_NEW',
  A_DOCUMENT_READ = 'A_DOCUMENT_READ',
  A_DOCUMENT_HISTORY = 'A_DOCUMENT_HISTORY',
  A_DOCUMENT_KITS_ALL = 'A_DOCUMENT_KITS_ALL'
}

export const enum DocumentsMutationTypes {
  M_DOCUMENTS_INCOMING = 'M_DOCUMENTS_INCOMING',
  M_DOCUMENTS_EXECUTIVE = 'M_DOCUMENTS_EXECUTIVE',
  M_DOCUMENTS_ALL = 'M_DOCUMENTS_ALL',
  M_DOCUMENTS_TYPES = 'M_DOCUMENTS_TYPES',
  M_DOCUMENTS_BY_TYPES = 'M_DOCUMENTS_BY_TYPES',
  M_DOCUMENTS_UPLOAD = 'M_DOCUMENTS_UPLOAD',
  M_MAKE_LIST_WORKS = 'M_MAKE_LIST_WORKS',
  M_CLEAR_DOCUMENTS_BY_TYPES = 'M_CLEAR_DOCUMENTS_BY_TYPES',
  M_CLEAR_DOCUMENTS_EXECUTIVE = 'M_CLEAR_DOCUMENTS_EXECUTIVE',
  M_DELETE_DOCUMENTS_BY_TYPES = 'M_DELETE_DOCUMENTS_BY_TYPES',
  M_FETCH_DOCUMENTS_BY_VOLUME = 'M_FETCH_DOCUMENTS_BY_VOLUME',
  M_CHANGE_STATUS = 'M_CHANGE_STATUS',
  M_FETCH_DOCUMENTS_STATUS = 'M_FETCH_DOCUMENTS_STATUS',
  M_SET_DOCUMENTS_COUNT_NEW = 'M_SET_DOCUMENTS_COUNT_NEW',
  M_SET_DOCUMENTS_EXEC_COUNT_NEW = 'M_SET_DOCUMENTS_EXEC_COUNT_NEW',
  M_DOCUMENT_READ = 'M_DOCUMENT_READ',
  M_DOCUMENT_HISTORY = 'M_DOCUMENT_HISTORY',
  M_DOCUMENT_KITS_ALL = 'M_DOCUMENT_KITS_ALL'
}
