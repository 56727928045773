export const enum UsersActions {
  A_GET_USERS = 'A_GET_USERS',
  A_GET_USERS_FOR_PROJECT = 'A_GET_USERS_FOR_PROJECT',
  A_GET_USER = 'A_GET_USER',

  A_CREATE_USER = 'A_CREATE_USER',
  A_UPDATE_USER = 'A_UPDATE_USER',
  A_DELETE_USER = 'A_DELETE_USER',

  A_CHANGE_PASSWORD_USER = 'A_CHANGE_PASSWORD_USER',

  A_FETCH_USER_ROLES = 'A_FETCH_USER_ROLES',

  A_FETCH_NATURAL_PERSONS = 'A_FETCH_NATURAL_PERSONS',
  A_FETCH_FILTERED_NATURAL_PERSONS = 'A_FETCH_FILTERED_NATURAL_PERSONS',
  A_FETCH_LEGAL_PERSONS = 'A_FETCH_LEGAL_PERSONS',
  A_FETCH_FILTERED_LEGAL_PERSONS = 'A_FETCH_FILTERED_LEGAL_PERSONS',

  A_FETCH_BY_ROLES_LEGAL_PERSONS = 'A_FETCH_BY_ROLES_LEGAL_PERSONS',

  A_UPDATE_LEGAL_PERSON = 'A_UPDATE_LEGAL_PERSON',

  A_TOGGLE_PERSON_ACTIVE = 'A_TOGGLE_PERSON_ACTIVE',
  A_TOGGLE_USER_ACTIVE = 'A_TOGGLE_USER_ACTIVE',

  A_CONNECT_NATURAL_PERSON = 'A_CONNECT_NATURAL_PERSON',
  A_DISCONNECT_NATURAL_PERSON = 'A_DISCONNECT_NATURAL_PERSON',

  A_CONNECT_USER_TO_PROJECT = 'A_CONNECT_USER_TO_PROJECT',
  A_DISCONNECT_USER_TO_PROJECT = 'A_DISCONNECT_USER_TO_PROJECT',

  A_CHANGE_ACCESS_USER_STATUSES = 'A_CHANGE_ACCESS_USER_STATUSES',

  A_GET_LOGS = 'A_GET_LOGS'
}

export const enum UsersMutations {
  M_SET_USERS = 'M_SET_USERS',
  M_SET_USER = 'M_SET_USER',

  M_UPDATE_USER = 'M_UPDATE_USER',

  M_FETCH_USER_ROLES = 'M_FETCH_USER_ROLES',

  M_FETCH_NATURAL_PERSONS = 'M_FETCH_NATURAL_PERSONS',
  M_FETCH_LEGAL_PERSONS = 'M_FETCH_LEGAL_PERSONS',
  M_FETCH_BY_ROLES_LEGAL_PERSONS = 'M_FETCH_BY_ROLES_LEGAL_PERSONS',

  M_UPDATE_LEGAL_PERSON = 'M_UPDATE_LEGAL_PERSON',

  M_TOGGLE_PERSON_ACTIVE = 'M_TOGGLE_PERSON_ACTIVE',
  M_TOGGLE_USER_ACTIVE = 'M_TOGGLE_USER_ACTIVE',

  M_CLEAR_USERS = 'M_CLEAR_USERS',
}
