import Vue from 'vue'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'

import { parse } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

import App from './App.vue'
import router from './router'
import store from './store'

import '@/style/index.scss'

Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.component('v-label', () => import('@/components/Label.vue'))
Vue.component('v-text', () => import('@/components/Text.vue'))
Vue.component('v-status', () => import('@/components/Status.vue'))
Vue.component('v-form-comment', () => import('@/components/forms/FormComment.vue'))
Vue.component('v-content-loader', () => import('@/components/ContentPreloader.vue'))

// Filters
Vue.filter('date', (date: string, formatString: string = 'dd.MM.yyyy') => {
  const dateFns = parse(date, 'yyyy-MM-dd HH:mm:ss xxx', new Date())
  const timeZone = 'UTC'

  return format(utcToZonedTime(dateFns, timeZone), formatString)
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$successNotify = function (message = 'Действие успешно выполнено', title = 'Выполнено') {
  this.$notify({title, message, type: 'success'})
}

Vue.prototype.$errorNotify = function (message = 'Произошла ошибка', title = 'Ошибка') {
  this.$notify({title, message, type: 'error'})
}

Vue.prototype.$scrollToTop = () => {
  let scrolled = window.pageYOffset

  function scrollToTop() {
    if (scrolled > 0) {
      window.scrollTo(0, scrolled)
      scrolled -= 50
      requestAnimationFrame(scrollToTop)
    } else {
      window.scrollTo(0, 0)
    }
  }

  requestAnimationFrame(scrollToTop)
}

declare module 'vue/types/vue' {
  interface Vue {
    $successNotify: (message?: string, title?:string) => void;
    $errorNotify: (message?: string, title?:string) => void;
    $scrollToTop: () => void
  }
}
