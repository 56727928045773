import { ActionTree } from 'vuex'
import { IRootState } from '@/store'
import {IWorksState, IFact, IWorkStruct} from './Interface'
import { WorksActionTypes, WorksMutationTypes } from './Types'
import http from '@/http'
import { AxiosResponse } from 'axios'

export const actions: ActionTree<IWorksState, IRootState> = {
  async [WorksActionTypes.A_WORKS]({ commit }, params): Promise<any> {
    try {
      const response = await http.get('works', {
        params: {
          project_id: params.project,
          work_struct_id: params.workStructId ?? null,
          document_kit_id: params.documentKitId ?? null,
          percent_sort: params.percentSort,
          date_sort: params.dateSort,
          page: params.insidePage,
          count: 8,
          search: params.search,
          offset: params.offset,
        },
      })

      commit(WorksMutationTypes.M_WORKS, response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_FETCH_WORK_STRUCTS]({ commit }, params): Promise<any> {
    try {
      const response = await http.get('work_structs', {params})

      if(response){
        commit(WorksMutationTypes.M_FETCH_WORK_STRUCTS, response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_ADD_WORK_STRUCT]({ commit }, workStruct:IWorkStruct): Promise<IWorkStruct> {
    try {
      const response = await http.post(`work_structs`, {
        name: workStruct.name,
        project_id: workStruct.projectId,
        parent_id: workStruct.parentId
      })
      commit(WorksMutationTypes.M_ADD_WORK_STRUCT, response.data)

      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_UPDATE_WORK_STRUCT]({ commit }, workStruct:IWorkStruct): Promise<IWorkStruct> {
    try {
      const response = await http.put(`work_structs/${workStruct.id}` , {
        name: workStruct.name,
        project_id: workStruct.projectId,
        parent_id: workStruct.parentId
      })
      commit(WorksMutationTypes.M_UPDATE_WORK_STRUCT, response.data)

      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_DELETE_WORK_STRUCT]({ commit }, id:number): Promise<{message:string}> {
    try {
      const response = await http.delete(`work_structs/${id}`)

      commit(WorksMutationTypes.M_DELETE_WORK_STRUCT, id)

      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_CAN_DELETE_WORK_STRUCT]({ commit }, id:number): Promise<{message:string}> {
    try {
      const response = await http.get(`work_structs/has_children/${id}`)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_ADD_FACT]({ commit }, fact): Promise<any> {
    try {
      const data = new FormData()

      data.set('projectId', fact.projectId)
      data.set('date', fact.expirationDate)
      data.set('dateStart', fact.startDate)
      data.set('name', fact.name)
      data.set('amount', fact.amount.toString().replace(/,/, '.'))

      fact.fileList.forEach(file => {
        data.append('documents[]', file.file.raw)
      })

      const response = await http.post(`v2/works/${fact.workId}/facts`, data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_FETCH_FACT]({ commit }, workId): Promise<IFact[]> {
    try {
      const { data }: AxiosResponse<IFact[]> = await http.get(`works/${workId}/facts`)

      commit(WorksMutationTypes.M_FETCH_FACT, data)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_ADD_MULTI_FACT]({ commit }, params): Promise<any> {
    try {
      await http.post(`works/${params.workId}/multi-formation`, { fact_ids: params.list })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async [WorksActionTypes.A_ADD_SEND_FORMATION]({ commit }, fact): Promise<any> {
    try {
      const data = new FormData()

      data.set('projectId', fact.projectId)
      data.set('date', fact.expirationDate)
      data.set('dateStart', fact.startDate)
      data.set('name', fact.name)
      data.set('amount', fact.amount)

      fact.fileList.forEach(file => {
        data.append('documents[]', file.file.raw)
      })

      return  await http.post(`works/${fact.workId}/formation`, data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
