export const enum TeamActions {
  A_FETCH_TEAM = 'A_FETCH_TEAM',

  A_FETCH_ORDERS = 'A_FETCH_ORDERS',
  A_CREATE_ORDER = 'A_CREATE_ORDER',

  A_INVITE_SPECIALIST = 'A_INVITE_SPECIALIST',
  A_DISABLE_SPECIALIST = 'A_DISABLE_SPECIALIST',
}

export const enum TeamMutations {
  M_FETCH_TEAM = 'M_FETCH_TEAM',
  M_CLEAR_TEAM = 'M_CLEAR_TEAM',

  M_FETCH_ORDERS = 'M_FETCH_ORDERS',
  M_DISABLE_SPECIALIST = 'M_DISABLE_SPECIALIST',
}
